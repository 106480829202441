.TimelineChart {
}
.TimelineChart_table {
    font-size: 0.9em;
}
.TimelineChart_table-tr--odd {
    background-color: lighten(@black, 95%);
}
.TimelineChart_table tr {
    > td,
    > th {
        padding: 2px 5px;
    }
}
.TimelineChart_legend-color-dot {
    display: inline-flex;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";