.StatusPieDiagram {
    .g2-html-annotation {
        z-index: 0 !important;
    }
    .StatusPieDiagram_diagram_wrap {
        margin: 0 auto;
    }
}

.StatusPieDiagram_diagram {
}
.StatusPieDiagram_legend {
    flex-wrap: wrap;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";