.ClusterTopologySmall {
    padding: 5px;
    border-radius: @border-radius-base;
    background: inherit;
    .ClusterTypologySmall_group {
        border: 1px dashed lighten(@black, 80%);
        border-radius: @border-radius-base;
        background: darken(@white, 0.5%);
        padding: 5px;
        .ClusterTypologySmall_group-title {
            font-size: 10px;
            margin: 0px 5px 0px 5px;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";