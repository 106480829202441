.RedisShardedShardFormWrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    .RedisShardedShardFormWrapper_button {
        &.RedisShardedShardFormWrapper_button--error {
            border-color: @error-color;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";