.SystemLogsPage_Wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 10px;
}

.SystemLogsPage_header {
    padding-bottom: 15px;
}

.SystemLogsPage {
    display: flex;
    height: 100%;
    .SystemLogsPage_TreeWrap {
        padding: 15px 20px 0 0;
        border-top: 1px solid @border-color-base;
    }
    .SystemLogsPage_Text {
        min-height: 500px;
        width: 100%;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";