.LoadingPage {
    height: 100vh;
    background-color: #0a1250;
    background-image: url(../components/Auth/auth-bg.svg);
    display: flex;
    flex-direction: column;

    .LoadingPage_loader {
        color: @white;
        width: 200px;
        margin: auto;
        height: 100px;
        display: block;
        font-size: 24px;
    }

    .LoadingPage_carousel {
        width: 50px;
        font-size: 36px;
        color: @white;

        text-align: center;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";