.RegistrationFullPageContainer {
    .RegistrationFullPage {
        .RegistrationFullPage_title {
            font-size: 2em;
            font-weight: bold;
            margin-top: 0;
            margin-left: 10px;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";