.ResponsiveTable_expanded-row {
    //> td {
    //    padding: 0 !important;
    //}
    //.ResponsiveTable_row {
    //    > td {
    //        background-color: lighten(@black, 95%);
    //    }
    //}
    //thead {
    //    visibility: collapse;
    //}
    //.ant-table.ant-table-middle {
    //    margin: 0 !important;
    //}
}
.BackupsTable_row--not-expandable {
    .ant-table-row-expand-icon-cell {
        //hidding expand icon by css because it is not customizable in the antd component.
        > * {
            display: none;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";