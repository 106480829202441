.AppDirectoryTree {
    &.ant-tree.ant-tree-directory {
        .ant-tree-treenode-selected {
            padding-right: 15px;
        }
        .ant-tree-treenode-selected:before,
        .ant-tree-treenode-selected:hover:before {
            background: @tree-node-selected-bg;
            border-radius: @border-radius-base;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";