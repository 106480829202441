.FormItemInline {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    > :first-child {
        padding: 0;
        //flex: 1;
    }
    > :nth-child(2) {
        margin-left: 10px;
        //flex: 0;
    }

    &.FormItemInline--justify {
        > :first-child {
            flex: 1;
        }
        > :nth-child(2) {
            margin-left: 0;
            flex: 0;
        }
    }
    &.FormItemInline--no-margin {
        margin: 0;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";