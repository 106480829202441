.AppSplitLayout {
    display: flex;
    height: 100%;

    .AppSplitLayout_Left {
        border-right: 1px solid @border-color-base;
        padding-right: 20px;

        &--no-split-border {
            border-right: none;
        }
    }

    .AppSplitLayout_Right {
        flex: 1;
        padding-left: 20px;
    }

    &--tab-content {
        margin-top: -16px;

        .AppSplitLayout_Left {
            padding-top: 16px;

            &--no-padding {
                padding: 0;
            }
        }

        .AppSplitLayout_Right {
            padding-top: 16px;
            min-width: 0;

            &--no-padding {
                padding: 0;
            }
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";