.ClusterSubmenu_overlay {
    > ul {
        padding-top: 10px;
        > li {
            padding-right: 30px;
        }
        overflow: hidden;
    }
}

.ClusterPageMenu {
    background: @component-background;
    border-bottom: @border-color-base @border-style-base @border-width-base;
    .ant-tabs-nav {
        &::before {
            border-bottom: 0;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";