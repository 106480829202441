.MonitorBox {
    border: 1px solid @border-color-base;
    padding: 15px;
    border-radius: 5px;
    height: 100%;

    .MonitorBox_header {
        display: flex;
        justify-content: space-between;
    }
}
.MonitorBox_expanded-modal-content {
    padding: 30px;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";