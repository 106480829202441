.ClusterFilter_overlay {
    width: 650px;
}
.ClusterFilter_content {
    padding: 10px;
}
.ClusterFilter_sort-select {
    min-width: 150px;
}
.ClusterFilter_filter-button-arrow {
    font-size: 12px;
    color: @disabled-color;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";