.SwitchBox {
    flex-direction: row-reverse !important;
    flex-wrap: nowrap;
    align-items: baseline;
    border: 1px solid @border-color-base;
    border-radius: @border-radius-large;

    &.SwitchBox--checked {
        border-color: @primary-color;
    }
    .ant-switch {
        // for some reason button width is changing between on/off so i fixed it
        width: 50px;
    }

    padding: 10px;

    .SwitchBox_label {
        padding-left: 10px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";