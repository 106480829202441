.TypographyText--type-muted.ant-typography {
    color: lighten(@black, 50%);
}

.TypographyText--type-primary.ant-typography {
    color: @primary-color;
}

.TypographyText--type-nowrap.ant-typography {
    white-space: nowrap;
}
.TypographyText--type-white.ant-typography {
    color: @white;
}

.TypographyText--type-strong.ant-typography {
    font-weight: 500;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";