.AppTable {
    &.ResponsiveTable--as-list-view:not(.ResponsiveTable--responsive) {
        .ResponsiveTable_row--as-list-view {
            > .ant-table-cell:first-child {
                border-radius: @border-radius-medium 0 0 @border-radius-medium;
            }
            > .ant-table-cell:last-child {
                border-radius: 0 @border-radius-medium @border-radius-medium 0;
            }
            &.ClusterList_row--expandable {
                > .ant-table-cell:first-child {
                    border-radius: @border-radius-medium 0 0 0;
                }
                > .ant-table-cell:last-child {
                    border-radius: 0 @border-radius-medium 0 0;
                }
            }
        }
        .ResponsiveTable_expanded-row {
            > .ant-table-cell {
                border-radius: 0 0 @border-radius-medium @border-radius-medium;
            }
        }
    }
    &.ResponsiveTable--responsive .ResponsiveTable_row {
        border-radius: @border-radius-medium;
    }
}
// @todo: fix when there antd supports customization for filterDropdown in an easy way
.ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
    background-color: transparent;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";