.UiNotification_modal_job-created {
    &.UiNotification_modal_job-created--size-large {
        .ant-modal-confirm-btns {
            display: none;
        }
    }
}
.UiNotification_modal {
    .ant-modal-confirm-btns {
        // did not find a way of setting footer nool to Modal.* functions so we hide them using css for now
        display: none;
    }
    &.UiNotification_modal--size-large {
        min-height: 800px;
    }
    &.UiNotification_modal_success {
    }
}
.UiNotification_icon {
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: -100px;
}

@media (max-width: 576px) {
    .UiNotification_modal {
        &.UiNotification_modal--size-large {
            min-width: 100%;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";