.AppActionButton {
    height: inherit;
    color: @black;

    &.AppActionButton--center-loading {
        .ant-btn-loading-icon {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -7px;
            margin-top: -7px;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";