.PageMenu {
    > .ant-tabs-nav {
        margin: 0;
    }
    .ant-tabs-nav-wrap {
        padding-right: 30px;
    }
    .ant-tabs-nav-list {
        flex-wrap: wrap;
        justify-content: left;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";