.WrapFormat_popover-overlay {
    .ant-popover-title {
        padding: 0;
    }
    &.WrapFormat_popover-overlay--status-success,
    &.WrapFormat_popover-overlay--status-error,
    &.WrapFormat_popover-overlay--status-warning,
    &.WrapFormat_popover-overlay--status-info,
    &.WrapFormat_popover-overlay--status-default {
        &.ant-popover-placement-bottom {
            .ant-popover-arrow-content::before {
                background: none;
            }
        }
    }
    &.WrapFormat_popover-overlay--status-success {
        &.ant-popover-placement-bottom {
            .ant-popover-arrow-content {
                border-top-color: @success-color;
                border-left-color: @success-color;
                background-color: @success-color;
            }
        }
    }
    &.WrapFormat_popover-overlay--status-error {
        &.ant-popover-placement-bottom {
            .ant-popover-arrow-content {
                border-top-color: @error-color;
                border-left-color: @error-color;
                background-color: @error-color;
            }
        }
    }
    &.WrapFormat_popover-overlay--status-warning {
        &.ant-popover-placement-bottom {
            .ant-popover-arrow-content {
                border-top-color: @warning-color;
                border-left-color: @warning-color;
                background-color: @warning-color;
            }
        }
    }
    &.WrapFormat_popover-overlay--status-info {
        &.ant-popover-placement-bottom {
            .ant-popover-arrow-content {
                border-top-color: @info-color;
                border-left-color: @info-color;
                background-color: @info-color;
            }
        }
    }
    &.WrapFormat_popover-overlay--status-default {
        &.ant-popover-placement-bottom {
            .ant-popover-arrow-content {
                border-top-color: lighten(@black, 60%);
                border-left-color: lighten(@black, 60%);
                background-color: lighten(@black, 60%);
            }
        }
    }
}
.WrapFormat_title {
    padding: 13px 16px;
    border-radius: @border-radius-medium @border-radius-medium 0 0;
    border-bottom: 0;
    &.WrapFormat_title--status-success {
        background-color: @success-color;
        color: @white;
    }
    &.WrapFormat_title--status-error {
        background-color: @error-color;
        color: @white;
    }
    &.WrapFormat_title--status-warning {
        background-color: @warning-color;
        color: @white;
    }
    &.WrapFormat_title--status-info {
        background-color: @info-color;
        color: @white;
    }
    &.WrapFormat_title--status-default {
        background-color: lighten(@black, 60%);
        color: @white;
    }
}
.WrapFormat_content {
    &.WrapFormat_content--size-large,
    &.WrapFormat_content--size-xlarge {
        min-height: 50px;
        padding: 10px 12px;
        border-radius: @border-radius-base;
        background-color: lighten(@black, 95%);
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";