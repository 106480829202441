.MonitorToolbar {
    justify-content: space-between;
    align-items: end;
}
.MonitoringDashboardFilter {
    &_left {
        display: flex;
        > * {
            padding-right: 20px;
        }
    }
}

.MonitorToolbar_refresh-menu {
    .ant-dropdown-menu-item-group-list {
        margin: 0;
    }
}
.MonitorToolbar_refresh-menu-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";