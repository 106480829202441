.TagsList {
    width: 100%;
    color: @text-color;

    &.ant-select-multiple.ant-select.ant-select-disabled
        > .ant-select-selector {
        border: none;
        background: none;
        cursor: default;
    }
    &.ant-select-disabled.ant-select.ant-select-multiple
        .ant-select-selection-item {
        color: @text-color;
        background: none;
        cursor: default;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";