.SpaceDescriptions {
    > .ant-space-item {
        width: inherit;
    }
    &.SpaceDescriptions--wide {
        width: 100%;
        > .ant-space-item {
            width: 100%;
        }
        .SpaceDescriptionsItem {
            width: 100%;
            > .ant-space-item {
                width: 100%;
            }
        }
    }
    &.SpaceDescriptions--align_title-right.SpaceDescriptions--with-title {
        > .ant-space-item:first-child {
            text-align: right;
        }
    }
    &.SpaceDescriptions--align_items-right.SpaceDescriptions--with-title {
        > .ant-space-item:not(:first-child) {
            text-align: right;
        }
    }
    &.SpaceDescriptions--align_items-right:not(.SpaceDescriptions--with-title) {
        > .ant-space-item {
            text-align: right;
        }
    }
}

.SpaceDescriptionsItem {
    .SpaceDescriptionsItem_label {
        display: inline-block;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";