.AppRow {
    &.AppRow--with-divider {
        > .ant-col:not(:last-child):after {
            border-right: 1px solid lighten(@black, 90%);
            content: '';
            position: absolute;
            top: 15%;
            bottom: 15%;
            right: 0;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";