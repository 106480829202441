.FormFooter {
    padding: 1px;
}
.FormFooter--align-right {
    justify-content: flex-end;
}
.FormFooter--align-left {
}
.FormFooter--align-center {
    justify-content: center;
}
.FormFooter--noDivider {
    padding-top: 20px;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";