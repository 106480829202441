.ProxySQLVariables {
    min-height: 750px;
}

.ProxySQLVariables__Search {
    padding: 8px;

    .ProxySQLVariables--input {
        margin-bottom: 8px;
        display: block;
    }

    .ProxySQLVariables--button {
        width: 115px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";