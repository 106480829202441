.NodesHoneycomb_Hexagon_content {
    position: relative;
    flex: 1;
    height: 100%;
}

.NodesHoneycomb_Hexagon_content-extra {
    position: absolute;
    top: -7px;
    right: -5px;
}

.NodesHoneycomb_Hexagon_extra-center {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -8px;
    margin-left: -8px;
    width: 0;
    height: 0;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";