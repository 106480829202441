.Avatar {
    border-radius: 50%;
    padding-top: 14px;
    &--size-xsmall {
        height: 35px;
        width: 35px;
        padding-top: 7px;
    }
    &--size-small {
        height: 50px;
        width: 50px;
    }
    &--size-large {
        height: 100px;
        width: 100px;
        padding-top: 22px;
    }
    &--avatar-icon {
        padding-top: 18px;
    }
    &--avatar-icon.Avatar--size-small {
        padding-top: 10px;
    }
    &--avatar-icon.Avatar--size-xsmall {
        padding-top: 4px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";