.ClusterListMini {
}

.ClusterListMini_table {
    .ant-table-thead {
        .ant-table-cell {
            color: @text-color-secondary;
        }
    }
    .ant-table-middle {
        .ant-table-tbody > tr > td.ant-table-cell {
            padding: 8px;
        }
    }
}
.ClusterListMini_cluster-icon {
    margin-right: 7px;
}

.ClusterListMini_status-column {
    white-space: nowrap;
}

.ClusterListMini_deployment-progress {
    flex-grow: 1;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";