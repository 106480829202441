.MaxScaleDetailsModal {
    padding: 0;
    > div {
        margin-bottom: 1rem;
    }
}

.MaxScaleDetailsModalPage {
    min-height: 550px;
}

.MaxScaleDetailsModalAutoComplete {
    min-width: 200px;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";