.MailServerForm {
    .MailServerForm_Title {
        position: relative;
        top: 17px;
        font-size: 25px;
        .MailServerForm_Recommended {
            margin-left: 15px;
            color: @primary-color;
            background-color: fade(@primary-color, 10%);
            padding: 5px 10px;
            border-radius: 10px;
            font-size: 12px;
            position: relative;
            top: -3px;
        }
    }
    .BoxSelect_item-content {
        height: 80px !important;
    }

    .WizardSelectCardStep_Item_footer {
        button {
            position: relative;
            top: -25px;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";