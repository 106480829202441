.ResetPasswordPage {
    display: flex;
    flex-direction: column;

    .ResetPasswordPage_body {
        flex-grow: 1;
    }
    .ResetPasswordPage_back {
        font-size: 1.2em;
        padding: 0.2em;
        border: solid 1px lighten(@black, 85%);
        border-radius: 50%;
        width: 2em;
        height: 2em;
        text-align: center;
        color: lighten(@black, 15%);
        display: block;
    }

    .ResetPasswordPage_title {
        font-size: 1.5em;
        font-weight: bold;
        margin-top: 20px;
    }
    .ResetPasswordPage_code {
        font-size: 0.95em;
        margin: 0;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";