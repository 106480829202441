.StatisticBadge {
    color: inherit;
    &.StatisticBadge--status-error {
        .ant-badge-count,
        .ant-badge-dot {
            background-color: @error-color;
        }
    }
    &.StatisticBadge--status-success {
        .ant-badge-count,
        .ant-badge-dot {
            background-color: @success-color;
        }
    }
    &.StatisticBadge--status-warning {
        .ant-badge-count,
        .ant-badge-dot {
            background-color: @warning-color;
        }
    }
    &.StatisticBadge--status-info {
        .ant-badge-count,
        .ant-badge-dot {
            background-color: @info-color;
        }
    }
    &.StatisticBadge--status-default {
        .ant-badge-count,
        .ant-badge-dot {
            background-color: lighten(@black, 60%);
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";