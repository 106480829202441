.AppTooltip_overlay {
    &.AppTooltip_overlay--size-large {
        max-width: 700px;
    }

    .ant-tooltip-inner {
        border-radius: @border-radius-base;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";