.AppEditor_Tabs {
    .ant-tabs-content {
        height: 100%;
    }
    .ant-tabs-nav {
        padding-left: 10px;
        margin: 0;
    }
    .ant-tabs-tab .anticon {
        margin: 0;
    }
    flex: 1;
}
.AppEditor_TabsWrap {
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid @border-color-split;
    border-top: none;
    border-bottom-right-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
}

.AppEditor {
    min-height: 500px;

    .AppEditor_TreeWrap {
    }
    .AppEditor_EditorWrap {
    }
}

.AppEditor_TabsFooter {
    border-top: 1px solid @border-color-split;
    padding: 10px 10px 8px 10px;
}

.AppEditor_ContentEmptyWrap {
    padding: 0 20px;

}

.AppEditor_ContentSkeletonWrap {
    padding: 20px;
    height: 100%;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";