.AuthWrapper {
    background-color: #0a1250;
    background-image: url(./auth-bg.svg);
    min-height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    overflow: auto;

    .AuthWrapper_wrapper {
    }

    .AuthWrapper_body {
        flex-grow: 0.3;
        height: 100vh;
        display: flex;
        flex-direction: column;
        max-width: 660px;
    }

    .AuthWrapper_welcome {
        flex-grow: 0.7;
        text-align: left;
        color: @white;

        display: flex;
        flex-direction: column;
        align-items: start;
        height: 100vh;

        .AuthWrapper_welcome_wrapper {
            font-size: 2.2em;
            font-style: normal;
            font-weight: bold;
            line-height: 2.5em;
            margin: auto;
            width: 450px;
            flex-grow: 0.6;
            display: flex;
            flex-direction: column;
            justify-content: end;
        }
        .AuthWrapper_copyright {
            color: lighten(@black, 75%);
            line-height: 2.5em;
            text-align: center;
            margin: 40px auto;
            width: 450px;
            text-align: left;
            flex-grow: 0.4;
            display: flex;
            flex-direction: column;
            justify-content: end;
            .AuthWrapper_copyright_logo {
                width: 120px;
            }
        }
    }
    .AuthWrapper_logo {
        width: 420px;
    }
    .AuthWrapper_content {
        flex-grow: 1;
        padding: 2em 10em 1em 10em;
        background-color: @component-background;
        display: flex;
        flex-direction: column;
    }
    .AuthWrapper_content_body {
        flex-grow: 1;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }
    .AuthWrapper_copyright_right-side {
        display: none; // not visible in desktop view
        color: lighten(@black, 75%);
        text-align: center;
        margin-top: auto;
        font-size: 0.9em;
    }
}

@media (max-width: 970px) {
    .AuthWrapper {
        flex-direction: column;

        .AuthWrapper_welcome {
            flex-grow: 0.2;
            min-height: 150px;
            display: flex;
            height: inherit;
            .AuthWrapper_welcome_wrapper {
                margin-bottom: 40px;
            }

            .AuthWrapper_copyright {
                display: none; // not visible on mobile
            }
        }
        .AuthWrapper_body {
            max-width: 100%;
            width: 100%;
            flex-grow: 0.8;
            height: inherit;
            .AuthWrapper_content {
                padding: 2em 4em 1em 4em;
                width: 100%;
            }
        }
        .AuthWrapper_copyright_right-side {
            display: block;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";