.ClusterTopology {
    background: darken(@white, 3%);
    padding: 5px;
    border-radius: @border-radius-base;
    .ClusterTypology_group {
        border: 1px dashed lighten(@black, 80%);
        border-radius: @border-radius-base;
        padding: 10px;
        background: darken(@white, 0.5%);
    }
    .ClusterTypology_group-title {
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";