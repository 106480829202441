.ClusterTopologySmallNodeItem {
    position: relative;
    width: 100%;
    height: 100%;
    .ClusterTopologySmallNodeItem_content-extra {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";