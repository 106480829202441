.DashboardCard {
    width: 100%;
    position: relative;
    .DashboardCard_container {
        margin-bottom: 0px;
    }
    .DashboardCard_loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .DashboardCard_extra {
        display: flex;
    }
    .DashboardCard_footer {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        border-top: 1px solid lighten(@black, 95%);
        display: flex;
        justify-content: flex-end;
        padding-top: 12px;
        padding-bottom: 12px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";