.KeyManagementTree {
    .anticon-file-text,
    .anticon-folder,
    .anticon-folder-open,
    .ant-tree-switcher_open,
    .ant-tree-switcher_close,
    .anticon-caret-down {
        color: #000;
    }
}
@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";