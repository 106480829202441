.ProxySQLMonitor {
    min-height: 750px;
}

.ProxySQLMonitor__Tables {
    margin-bottom: 2rem;
}

.ProxySQLMonitor__Link {
    margin-top: 2rem;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";