.ClusterConfigValueInput {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    .ClusterConfigValueInput_value {
        max-width: 90%;
        width: 100%;
        margin: 0 10px 0 0;
        display: flex;
        justify-content: space-between;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";