.AppPageWrapper {
    background-color: @layout-body-background;

    .PageWrapper_wrapper {
        position: initial;
    }
}
.AppPageWrapper_content {
    border-radius: @content-border-radius;
    border: @content-border;
    background-color: @white;
}
.AppPageWrapper_header {
    border-radius: @content-border-radius;
    border: @content-border;
    background-color: @content-background-color;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";