.ActivityQuickView {
    background-color: @component-background;
    margin-top: -1000px;
    transition: margin-top 0.3s;
    transition-timing-function: ease-in-out;

    &.ActivityQuickView--visible {
        margin-top: 0;
    }

    .ActivityQuickView_menu {
        .ant-radio-button-wrapper {
            padding-left: 25px;
            padding-right: 25px;
        }
    }
    .ActivityQuickView_content {
        margin-top: 20px;
    }
}

@media (max-width: 970px) {
    .ActivityQuickView {
        margin-top: -2000px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";