.ActionsMenu {
    display: inline-flex;
    background-color: transparent;
    border-radius: @border-radius-base;
    > li {
        line-height: 20px !important;
    }
    &.ActionsMenu--disabled {
        background-color: @disabled-bg;
    }
    .ActionsMenu_more-button {
        width: 40px;
        height: 20px;
        padding: 0;
        background-color: white;

        &.ActionsMenu_more-button--bordered {
            border: 1px solid @border-color-base;
            height: 40px;
        }
    }

    &::before {
        content: none;
    }

    &.ActionsMenu--size-small {
        .ActionsMenu_more-button {
            width: 20px;
            height: 20px;
            padding: 0;
        }
    }
    &.ActionsMenu--size-middle {
        .ActionsMenu_more-button {
            width: 32px;
            height: 32px;
            padding: 0;
        }
    }
}
.ActionsMenu_ItemWrap {
    /* overriding button link color */
    .ant-btn-link {
        color: @text-color;
    }
}
.ActionsMenu_overlay {

    // @TODO: move to components
    // making the whole button to be clickable in dropdown
    .ant-dropdown-menu-item.ActionMenu_Item {
        padding: 0;
        > button {
            padding: 5px 19px;
            height: 34px;
        }
    }
    ///////
    .ActionMenu_more-menu {
        border-radius: @border-radius-medium;
        overflow: hidden;
    }
}

.ActionsMenu_more-button {
    /* overriding button link color */
    &.ant-btn-link {
        color: @text-color;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";