.AppRadioButton {
    &:first-child {
        border-radius: @border-radius-medium 0 0 @border-radius-medium;
    }
    &:last-child {
        border-radius: 0 @border-radius-medium @border-radius-medium 0;
    }
}
.AppRadioGroup {
    margin-left: 3px;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";