.LicenseInfo {
    width: 770px;
}
.LicenseInfo_Alerts {
    min-height: 100px;
    padding: 20px 0;
}
.LicenseInfo_Nodes {
    width: 200px;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";