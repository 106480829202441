.SquareStatusBadge {
    text-align: center;
    border-radius: 3px;
    font-size: 0.9em;
}
.SquareStatusBadge_content {
    font-size: 1.3em;
    margin: auto;
    width: 40px;
    height: 40px;
    border: 2px solid;
    border-radius: 2px;
    line-height: 38px;
}

.SquareStatusBadge--status-error {
    color: @error-color;
    .ant-badge {
        color: @error-color;
    }
}

.SquareStatusBadge--status-success {
    color: @success-color;
    .ant-badge {
        color: @success-color;
    }
}

.SquareStatusBadge--status-warning {
    color: @warning-color;
    .ant-badge {
        color: @warning-color;
    }
}

.SquareStatusBadge--status-info {
    color: @info-color;
    .ant-badge {
        color: @info-color;
    }
}

.SquareStatusBadge_content--status-error {
    border-color: @error-color;
}

.SquareStatusBadge_content--status-success {
    border-color: @success-color;
}

.SquareStatusBadge_content--status-warning {
    border-color: @warning-color;
}

.SquareStatusBadge_content--status-info {
    border-color: @info-color;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";