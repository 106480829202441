.ContentWithLayout {
    &.ContentWithLayout--layout-null {
        .AppContent {
            padding: 0;
        }
        .AppContent-wrap {
            margin: 0;
            max-width: 100%;
        }
    }
}
.ContentWithLayout_sider {
    .ant-layout-sider-trigger {
        background-color: @dark-color;
        .anticon {
            color: @white;
        }
    }
}

.ContentWithLayout_footer {
    color: lighten(@black, 50%) !important;
}

.ContentWithLayout_header-extra-container {
    width: 100%;
    justify-content: space-between;
}
.ContentWithLayout_tech-preview {
    padding: 3px 7px;
}

//@TODO: move to components
// fixes weirdo horizontal scroll in side bar menu
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
}
//////
//@TODO: move to components
@media (max-width: 576px) {
    .AppLayout.AppLayout--with-fixed-header .AppLayout_header-bottom-extra,
    .AppLayout.AppLayout--with-fixed-full-header
        .AppLayout_header-bottom-extra {
        position: absolute !important;
    }
}
////////

// responsive
//@todo uppgrade component in common component to accept classname customization for header
.AppLayout.AppLayout--responsive {
    .ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
        color: @white;
    }
}

//Side menu
//@todo uppgrade component in common component to accept classname customization
.AppSider_menu {
    background-color: @dark-color !important;
    .ant-menu-item {
        color: @white;
        a {
            color: @white;
            &:hover {
                color: @primary-light-color;
            }
        }
        .ant-typography-ellipsis-single-line {
            color: @white !important;
        }
        &.ant-menu-item-selected {
            background-color: @white !important;
            color: @text-color !important;
            a {
                color: @text-color !important;
            }
            .ant-typography-ellipsis-single-line {
                color: @text-color !important
            }
        }
        &.ant-menu-item-active {
            svg {
                color: @primary-light-color;
            }
        }
        &.ant-menu-item-selected {
            svg {
                color: @text-color;
            }
        }
    }
    .ant-menu-sub {
        background-color: @dark-color !important;
        a {
            color: @text-color !important;
        }
        &.ant-menu-inline {
            .ant-menu-item {
                background-color: @dark-color !important;
                &.ant-menu-item-selected {
                    background-color: @white !important;
                    color: @text-color !important;
                    a {
                        color: @text-color !important;
                    }
                }
            }

            a {
                color: @white !important;
            }
        }
    }
    .ant-menu-submenu-selected {
        > div.ant-menu-submenu-title {
            background-color: @white !important;
            .ant-menu-title-content > a {
                color: @dark-color !important;
            }
        }
        .ant-menu-submenu-arrow {
            color: @dark-color !important;
        }
    }
    .ant-menu-submenu-title {
        a {
            color: @white !important;
            &:hover {
                color: @primary-light-color !important;
            }
        }
    }
    .ant-menu-submenu-arrow {
        color: @white;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";