.NodeList_statistics {
    .NodeList_statistics-item {
        flex: 1 1;
        cursor: pointer;
        border-left: 1px solid @border-color-base;
        border-top: 1px solid @border-color-base;
        border-bottom: 1px solid @border-color-base;
        &:first-child {
            border-top-left-radius: @border-radius-base;
            border-bottom-left-radius: @border-radius-base;
        }
        &:last-child {
            border-right: 1px solid @border-color-base;
            border-top-right-radius: @border-radius-base;
            border-bottom-right-radius: @border-radius-base;
        }
        &.NodeList_statistics-item--active,
        &:hover {
            border-color: @primary-color;
            border-right: 1px solid @primary-color;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";