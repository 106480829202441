.JobProgress {
    margin-bottom: 60px;
    &.JobProgress--no-margin {
        margin-bottom: 0;
    }
    .JobProgress_body {
        text-align: left;
        max-width: 600px;
    }
    .JobProgress_small-body {
        width: 100%;
        text-align: center;
        &.JobProgress_small-body--align-left {
            text-align: left;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";