.ModalDefault {
    .ant-modal-header {
        border-radius: @border-radius-large @border-radius-large 0 0;
        border-bottom: 1px solid @divider-color;
        padding: 24px 24px;
    }
    .ant-modal-content {
        border-radius: @border-radius-large;
    }

    .ant-modal-close-icon {
        border: 1px solid @border-color-base;
        padding: 5px;
        border-radius: @border-radius-base;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";