.DetailsModal {
}

.DetailsModal_title-wrapper {
    width: 100%;
    justify-content: space-between;
}

.DetailsModal_content-wrapper {
    width: 100%;
    background-color: transparent !important;
}
.DetailsModal_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 80px;
    height: 150px;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";