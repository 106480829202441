.AppSpin--status-error {
    color: @error-color;
}

.AppSpin--status-success {
    color: @success-color;
}

.AppSpin--status-warning {
    color: @warning-color;
}

.AppSpin--status-info {
    color: @info-color;
}
.AppSpin--tall {
    height: 100%;
    > .ant-spin-container {
        height: 100%;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";