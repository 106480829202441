.ClusterDashboards {
    .ClusterDashboards_content {
        padding: 0;
    }
    .ClusterDashboards_wrap {
        padding: 24px;
    }
    .ClusterDashboards_header {
        padding: 12px 24px 24px 24px;
        background-color: @toolbar-background;
        border-bottom: 1px solid @divider-color;
        border-radius: @border-radius-medium @border-radius-medium 0 0;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";