.AppIcon {
    &--autosize {
        width: 100%;
        height: 100%;
        .AppIcon_ant-icon {
            width: 100%;
            > svg {
                width: 100%;
                height: 100%;
            }
        }
    }
    .AppIcon-wrap {
        display: inline;
        position: relative;
        &--autosize {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.AppIcon_ant-icon {
}
.AppIcon_ant-icon--autosize {
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";