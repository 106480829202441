.TextExpand {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    &.TextExpand--expanded {
        -webkit-line-clamp: inherit;
    }
}
.TextExpand__button {
    padding: 0;
}
@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";