.Agreement {
    background-color: #0a1250;
    background-image: url(./auth-bg.svg);
    min-height: 100vh;
    display: flex;
    align-items: center;
    .Agreement_content {
        margin: auto;
        width: 800px;
        background-color: @component-background;
        padding: 40px;
        border-radius: 10px;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";