.SegmentedData {
    display: flex;

    .SegmentedData_item-wrap {
        padding: 5px 10px;
        border: 1px solid @border-color-base;
        border-right: none;

        &:first-child {
            padding-left: 15px;
            border-bottom-left-radius: @border-radius-base;
            border-top-left-radius: @border-radius-base;
        }
        &:last-child {
            border-bottom-right-radius: @border-radius-base;
            border-top-right-radius: @border-radius-base;
            padding-right: 15px;
            border-right: 1px solid @border-color-base;
        }
    }
    .SegmentedData_extra {
        background-color: @background-color-base;
    }
    .SegmentedData_item-data-wrap {
        width: 100%;
        overflow: hidden;
        background-color: white;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";