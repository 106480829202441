.PromoCard {
    .PromoCard_footer {
        & > .ant-space-item {
            margin-top: auto;
        }
    }
    &.PromoCard--bg-circles-top-left {
        background-image: url(./img-bg-circles-top-left.svg);
        background-repeat: no-repeat;
        background-position: -20% -50%;
        transition: background-position 0.2s;
    }
    &.PromoCard--bg-circles-bottom-right {
        background-image: url(./img-bg-circles-bottom-right.svg);
        background-repeat: no-repeat;
        background-position: 100% 100%;
        transition: background-position 0.2s;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";