.NodeTree {
    .ant-tree-treenode {
        width: 100%;
    }
    .ant-tree-node-content-wrapper {
        display: inline-block;
        width: 100%;
    }
    .ant-tree-title {
        display: inline-block;
        width: 100%;
    }
    .ant-tree-switcher-leaf-line::after {
        height: 25px;
    }
    .ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
        height: 25px !important;
    }
    &.NodeTree--fullWidth {
        .ant-tree-switcher_open {
            display: none;
        }
    }

    &.NodeTree--fitContent {
        .ant-tree-node-content-wrapper {
            min-width: 0;
        }
        .NodeTreeItem_content {
            min-width: 0;
        }
        .ant-space-horizontal {
            width: 100%;
            > .ant-space-item {
                min-width: 0;
            }
        }
    }
    .ant-tree-treenode-disabled {
        .ant-typography {
            color: @disabled-color;
        }
    }

}

.NodeTreeItem {
    background-color: lighten(@black, 95%);
    width: 100%;
    display: flex;
    border-radius: 10px;
    margin-bottom: 5px;
    transition: background-color 0.3s;

    &_wrap {
        width: 100%;
        min-height: 55px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        overflow: hidden;
        padding: 10px;
        background-color: inherit;
        border-radius: 10px;
    }

    &_footer {
        background-color: white;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        max-height: 0;
        overflow: hidden;
        //transition: max-height 0.5s ease-out;
    }

    &.NodeTreeItem--expanded {
        .NodeTreeItem_footer {
            display: block;
            max-height: 500px;
            padding: 5px 10px;
        }
    }

    &.NodeTreeItem--expandable {
        padding: 0 2px 2px 2px;
        flex-direction: column;
    }

    &.NodeTreeItem--status-info {
        background-color: lighten(@info-color, 35%);
        color: darken(@info-color, 20%);
        .ant-typography {
            color: darken(@info-color, 20%);
        }
    }
    &.NodeTreeItem--status-error {
        background-color: lighten(@error-color, 31%);
        color: darken(@error-color, 20%);
        .ant-typography {
            color: darken(@error-color, 20%);
        }
    }
    &.NodeTreeItem--status-success {
        background-color: lighten(@success-color, 46%);
        color: darken(@success-color, 20%);
        .ant-typography {
            color: darken(@success-color, 20%);
        }
    }

    .NodeTreeItem_icon {
        font-size: 1.2em;
    }
    .NodeTreeItem_content {
        flex: 1;
        margin-left: 10px;
        line-height: 20px;
    }
    .NodeTreeItem_status {
        font-size: 1.2em;
        margin-left: 10px;
    }

    .NodeTreeItem_extra {
        position: absolute;
        left: 100%;
        top: 0;
        bottom: 0;
        transition: opacity 0.2s;
        display: flex;
        align-items: center;
        opacity: 0;
        background-color: inherit;
        padding-right: 5px;
        //box-shadow: -1px 1px 6px 0px rgb(222 222 222);
    }

    &:hover {
        .NodeTreeItem_extra {
            left: initial;
            right: 0;
            opacity: 1;
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";