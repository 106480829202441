.StatisticBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-radius: 5px;
    color: @black;
    position: relative;
    min-height: 100%;

    &.StatisticBox--inline {
        flex-direction: row;
        padding: 0;

        .StatisticBox_loading-background {
            position: absolute;
            top: 3px;
            left: -16px;
            right: auto;
        }
        .StatisticBox_loading {
            font-size: inherit;
            margin: 0;
        }
    }

    .StatisticBox_title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 400;
        //text-align: center;
    }

    .StatisticBox_loading {
        font-size: 27px;
        margin: 10px;
    }

    .StatisticBox_footer {
        width: 100%;
        padding: 2px;
        .StatisticBox_footer-content {
            padding-top: 3px;
            border-top: 1px solid #000;
        }
    }

    .StatisticBox_inline-total {
        color: lighten(@black, 50%);
    }
    .StatisticBox_loading-background {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    &.StatisticBox--loading {
        opacity: 0.3;
        //.StatisticBox_title {
        //    color: fade(@black, 50%);
        //}
        //
        //.ant-statistic-content {
        //    color: fade(@black, 50%);
        //}
    }

    &.StatisticBox--highlight {
        background-color: fade(@black, 50%);

        .StatisticBox_title {
            font-weight: 500;
        }
        .StatisticBox_title,
        .ant-statistic-content,
        .StatisticBox_loading,
        .StatisticBox_loading-background,
        .StatisticBox_footer {
            color: @white !important;
        }

        .StatisticBox_footer {
            .StatisticBox_footer-content {
                border-top: 1px solid #fff;
            }
        }
    }
    &.StatisticBox--selectable:hover {
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
    }

    .ant-statistic {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;

        width: 100%;
    }
    .ant-statistic-title {
        width: 100%;
        text-align: left;
    }
    .ant-statistic-content {
        font-size: 26px;
        font-weight: 400;
    }

    &.StatisticBox--size-large {
        .ant-statistic-content {
            font-size: 30px;
        }
    }

    &.StatisticBox--type-info {
        .ant-statistic-content,
        .StatisticBox_inline-value,
        .StatisticBox_footer {
            color: @info-color;
        }
        &.StatisticBox--highlight {
            background-color: lighten(@info-color, 5%);
            .ant-statistic-content {
                color: @white;
            }
        }
    }

    &.StatisticBox--type-success {
        .ant-statistic-content,
        .StatisticBox_inline-value,
        .StatisticBox_footer {
            color: @success-color;
        }
        &.StatisticBox--highlight {
            background-color: lighten(@success-color, 5%);
            .ant-statistic-content {
                color: @white;
            }
        }
    }

    &.StatisticBox--type-warning {
        .ant-statistic-content,
        .StatisticBox_inline-value,
        .StatisticBox_footer {
            color: @warning-color;
        }
        &.StatisticBox--highlight {
            background-color: lighten(@warning-color, 5%);
            .ant-statistic-content {
                color: @white;
            }
        }
    }

    &.StatisticBox--type-error {
        .ant-statistic-content,
        .StatisticBox_inline-value,
        .StatisticBox_footer {
            color: @error-color;
        }
        &.StatisticBox--highlight {
            background-color: lighten(@error-color, 5%);
            .ant-statistic-content {
                color: @white;
            }
        }
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";