.AppSubLayout {
    .AppSubLayout_content {
        padding: 0 50px;
        transition: padding 0.2s;
    }
    .AppSubLayout_content-wrap {
        //padding: 24px;
        //min-height: 280px;
        max-width: 1400px;
        margin: 30px auto 0;
        transition: padding 0.2s;
    }
}

@media (max-width: 1200px) {
    .AppSubLayout_content {
        padding: 0 25px;
    }
    .AppContent-wrap--middle {
        padding-left: 6%;
        padding-right: 6%;
    }
}
@media (max-width: 992px) {
    .AppSubLayout_content {
        padding: 0 10px;
    }
    .AppSubLayout_content-wrap--middle {
        padding-left: 3%;
        padding-right: 3%;
    }
}
@media (max-width: 768px) {
    .AppSubLayout_content {
        padding: 0 10px;
    }
    .AppSubLayout_content-wrap--middle {
        padding-left: 1%;
        padding-right: 1%;
    }
}
@media (max-width: 576px) {
    .AppSubLayout_content {
        padding: 0 3px;
    }

    .AppSubLayout_content-wrap--middle {
        padding-left: 0;
        padding-right: 0;
    }
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";