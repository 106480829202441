.CoverView_wrapper {
    border: @border-width-base @border-style-base @border-color-base;
    min-height: 470px;
    display: flex;
    flex-direction: column;
    border-radius: @border-radius-medium;
}

.CoverView {
    height: 100%;
    flex-grow: 1;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    position: relative;
    padding-top: 80px;
    padding-bottom: 80px;
    > * {
        padding: 10px;
    }

    &.CoverView--empty {
        justify-content: center;
        align-items: center;
    }
}

.CoverView:before {
    content: '';
    pointer-events: none;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-repeat: no-repeat;
    background-position: left -150px center;
    background-size: 400px;
    mask-size: 400px;
    mask-repeat: no-repeat;
    mask-position: left -150px center;
}

.CoverView_default-icon {
    color: @primary-color;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";